import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Img from "gatsby-image"
import moment from "moment"
import MarkdownView from "react-showdown"
import {
  PostHeader,
  PostContainer,
  PostTags,
  PostTag,
  Tag,
  PostImageWrapper,
} from "../components/styled/post.js"
import { Container } from "../components/styled/container"
import { AdBanner, AdLayout } from "../components/adBanner/index"

const PostTemplate = ({ data }) => (
  <Layout>
    <AdLayout>
      <AdBanner slot="6517054732" />
      <SEO
        title={`${data.strapiPost.title} - FGCharts`}
        description={data.strapiPost.description}
      />
      <Container style={{ flex: "10 0 auto" }}>
        <div style={{ padding: "0 12px" }}>
          <PostHeader>
            <h2 style={{ marginBottom: 16 }}>{data.strapiPost.title}</h2>
            <p style={{ marginBottom: 0 }}>
              {`${data.strapiPost.author} - ${moment(
                data.strapiPost.createdAt
              ).format("LL")}`}
            </p>
            <PostTags>
              {data.strapiPost.tags.map((tag, index) => (
                <PostTag key={index} right={data.strapiPost.tags.length > 1}>
                  <Tag>{tag}</Tag>
                </PostTag>
              ))}
            </PostTags>
          </PostHeader>
          <PostImageWrapper>
            <Img fluid={data.strapiPost.featuredImage.childImageSharp.fluid} />
          </PostImageWrapper>
          <PostContainer>
            <MarkdownView
              markdown={data.strapiPost.content}
              options={{ table: true, emoji: true }}
            />
          </PostContainer>
        </div>
      </Container>
      <AdBanner slot="6517054732" />
    </AdLayout>
  </Layout>
)

export default PostTemplate

export const query = graphql`
  query PostTemplate($slug: String!) {
    strapiPost(slug: { eq: $slug }) {
      id
      title
      content
      author
      tags
      description
      createdAt
      featuredImage {
        childImageSharp {
          fluid(maxWidth: 780, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
`
